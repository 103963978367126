import "./AuthPage.scss"
import React from "react"
import { Form, Field } from "react-final-form"
import createDecorator from "final-form-focus"
import { Link } from "react-router-dom"

import { Required } from "../../Validation/FormValidation"

const Login = () => {
	const focusOnError = createDecorator()

	const onSubmit = formValues => {
		console.log(formValues)
	}

	return (
		<div className="Auth-page">
			<div className="Auth-comp">
				<div className="field">
					<h1>Login</h1>
					<span className="required">
						<small>Required Fields</small>
					</span>
				</div>
				<Form
					onSubmit={onSubmit}
					decorators={[focusOnError]}
					render={({ handleSubmit, submitting, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Field
								name="email"
								validate={Required}
								render={({ input, meta }) => (
									<div className="field-label-right">
										<label htmlFor="email" className="label required">
											email
										</label>
										<input
											{...input}
											type="email"
											id="email"
											className={`txt txt-larg ${
												meta.error && meta.touched && "bgc-danger-dim"
											}`}
										/>
									</div>
								)}
							/>

							<Field
								name="password"
								validate={Required}
								render={({ input, meta }) => (
									<div className="field-label-right">
										<label htmlFor="password" className="label required">
											Password
										</label>
										<input
											{...input}
											type="password"
											id="password"
											className={`txt txt-larg ${
												meta.error && meta.touched && "bgc-danger-dim"
											}`}
										/>
									</div>
								)}
							/>

							<div className="field flex justify-content-center">
								<button
									type="submit"
									disabled={submitting || pristine}
									className="btn btn-primary btn-low-margin"
								>
									<span className="btn__text">Login</span>
								</button>
							</div>
						</form>
					)}
				/>
				<div className="center">
					<Link to="/forgot-password">Forgot Password?</Link>
				</div>
				<br />
				<div className="hr"></div>
				<div className="field center">
					<p>
						If you are not a registered user then{" "}
						<Link to="/register">Register here.</Link>
					</p>
				</div>
			</div>
		</div>
	)
}

export default Login
