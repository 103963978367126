import "./MainBanner.scss"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import React from "react"
import { Carousel } from "react-responsive-carousel"

const MainBanner = () => {
	return (
		<div className="carousel-comp">
			<Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
				<div>
					<img
						src="/images/Ceylon-Sources_800x400.jpg"
						alt="logo"
						width="800px"
					/>
				</div>
			</Carousel>
		</div>
	)
}

export default MainBanner
