import "./AuthPage.scss"
import React from "react"
import { Form, Field } from "react-final-form"
import createDecorator from "final-form-focus"
import AuthCode from "react-auth-code-input"

import { Required, PasswordConfirm } from "../../Validation/FormValidation"

const ChangePassword = () => {
	const focusOnError = createDecorator()

	const onSubmit = formValues => {
		console.log(formValues)
	}

	return (
		<div className="Auth-page">
			<div className="Auth-comp">
				<div className="field">
					<h1>Change Password</h1>
					<span className="required">
						<small>Required Fields</small>
					</span>
				</div>

				<Form
					onSubmit={onSubmit}
					decorators={[focusOnError]}
					validate={PasswordConfirm}
					render={({ handleSubmit, submitting, pristine }) => (
						<form onSubmit={handleSubmit}>
							<Field
								name="otp"
								validate={Required}
								render={({ input, meta }) => (
									<div className="field-label-right">
										<label htmlFor="code" className="label required">
											Code
										</label>
										<AuthCode
											{...input}
											placeholder="_"
											inputClassName={`auth-code ${
												meta.error && meta.touched && "bgc-danger-dim"
											}`}
										/>
									</div>
								)}
							/>

							<div className="field center">
								<p>Please enter the code sent to the provided email address.</p>
							</div>
							<div className="hr"></div>

							<Field
								name="password"
								validate={Required}
								render={({ input, meta }) => (
									<div className="field-label-right">
										<label htmlFor="password" className="label required">
											Password
										</label>
										<input
											{...input}
											type="password"
											id="password"
											className={`txt txt-larg${
												meta.error && meta.touched && "bgc-danger-dim"
											} ${!meta.error && meta.touched && "bgc-success-dim"}`}
										/>
										{meta.error && meta.touched && (
											<span className="txt-danger">{meta.error}</span>
										)}
									</div>
								)}
							/>

							<Field
								name="confirm"
								validate={Required}
								render={({ input, meta }) => (
									<div className="field-label-right">
										<label htmlFor="confirm" className="label required">
											Confirm
										</label>
										<input
											{...input}
											type="password"
											id="confirm"
											className={`txt txt-larg${
												meta.error && meta.touched && "bgc-danger-dim"
											} ${!meta.error && "bgc-success-dim"}`}
										/>
										{meta.error && meta.touched && (
											<span className="txt-danger">{meta.error}</span>
										)}
									</div>
								)}
							/>

							<div className="field flex justify-content-center">
								<button
									type="submit"
									disabled={submitting || pristine}
									className="btn btn-primary btn-low-margin"
								>
									<span className="btn__text">Submit</span>
								</button>
							</div>
						</form>
					)}
				/>
			</div>
		</div>
	)
}

export default ChangePassword
