import "./AuthPage.scss"
import React from "react"
import { Form, Field } from "react-final-form"
import createDecorator from "final-form-focus"
import { Link } from "react-router-dom"

import {
	Required,
	OnlyNumbersInt,
	NumberToPhone,
	PasswordConfirm,
} from "../../Validation/FormValidation"

const Register = () => {
	const focusOnError = createDecorator()

	const onSubmit = formValues => {
		console.log(formValues)
	}
	return (
		<div className="Auth-page">
			<div className="Auth-comp">
				<div className="field">
					<h1>Register</h1>
					<span className="required">
						<small>Required Fields</small>
					</span>
				</div>

				<Form
					onSubmit={onSubmit}
					decorators={[focusOnError]}
					validate={PasswordConfirm}
					render={({ handleSubmit, submitting, pristine }) => (
						<form onSubmit={handleSubmit}>
							<div className="field-column">
								<Field
									name="f_name"
									validate={Required}
									render={({ input, meta }) => (
										<div className="field-label-right">
											<label htmlFor="first-name" className="label required">
												First Name
											</label>
											<input
												{...input}
												type="text"
												id="first-name"
												className={`txt txt-larg ${
													meta.error && meta.touched && "bgc-danger-dim"
												}`}
											/>
										</div>
									)}
								/>

								<Field
									name="l_name"
									validate={Required}
									render={({ input, meta }) => (
										<div className="field-label-right">
											<label htmlFor="last-name" className="label required">
												Last Name
											</label>
											<input
												{...input}
												type="text"
												id="Last-name"
												className={`txt txt-larg ${
													meta.error && meta.touched && "bgc-danger-dim"
												}`}
											/>
										</div>
									)}
								/>

								<Field
									name="email"
									validate={Required}
									render={({ input, meta }) => (
										<div className="field-label-right">
											<label htmlFor="email" className="label required">
												email
											</label>
											<input
												{...input}
												type="email"
												id="email"
												className={`txt txt-larg ${
													meta.error && meta.touched && "bgc-danger-dim"
												}`}
											/>
										</div>
									)}
								/>

								<Field
									name="mobile"
									validate={Required}
									initialValue=""
									parse={OnlyNumbersInt}
									format={NumberToPhone}
									render={({ input, meta }) => (
										<div className="field-label-right">
											<label htmlFor="mobile" className="label required">
												Mobile No.
											</label>
											<input
												{...input}
												type="text"
												id="mobile"
												className={`txt txt-larg ${
													meta.error && meta.touched && "bgc-danger-dim"
												}`}
											/>
										</div>
									)}
								/>

								<Field
									name="password"
									validate={Required}
									render={({ input, meta }) => (
										<div className="field-label-right">
											<label htmlFor="password" className="label required">
												Password
											</label>
											<input
												{...input}
												type="password"
												id="password"
												className={`txt txt-larg${
													meta.error && meta.touched && "bgc-danger-dim"
												} ${!meta.error && meta.touched && "bgc-success-dim"}`}
											/>
											{meta.error && meta.touched && (
												<span className="txt-danger">{meta.error}</span>
											)}
										</div>
									)}
								/>

								<Field
									name="confirm"
									validate={Required}
									render={({ input, meta }) => (
										<div className="field-label-right">
											<label htmlFor="confirm" className="label required">
												Confirm
											</label>
											<input
												{...input}
												type="password"
												id="confirm"
												className={`txt txt-larg${
													meta.error && meta.touched && "bgc-danger-dim"
												} ${!meta.error && "bgc-success-dim"}`}
											/>
											{meta.error && meta.touched && (
												<span className="txt-danger">{meta.error}</span>
											)}
										</div>
									)}
								/>

								<div className="field flex justify-content-center">
									<button
										type="submit"
										disabled={submitting || pristine}
										className="btn btn-primary btn-low-margin"
									>
										<span className="btn__text">Submit</span>
									</button>
								</div>
							</div>
						</form>
					)}
				/>

				<br />
				<div className="hr"></div>
				<div className="field center">
					<p>
						If you have already registered, <Link to="/login">Login</Link>{" "}
						instead.
					</p>
				</div>
			</div>
		</div>
	)
}

export default Register
