import "./Style/AppStyles.scss"
import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Header from "./Header/Header"
import Toasts from "./common/Toasts/Toasts"
import Home from "./Home/Home"
import Register from "./Authetication/Register"
import Login from "./Authetication/Login"
import ForgotPassword from "./Authetication/ForgotPassword"
import ChangePassword from "./Authetication/ChangePassword"

const App = () => {
	return (
		<BrowserRouter>
			<Header />
			<Toasts />
			<div className="container">
				<Routes>
					<Route path="/" exact element={<Home />} />
					<Route path="/register" exact element={<Register />} />
					<Route path="/login" exact element={<Login />} />
					<Route path="/forgot-password" exact element={<ForgotPassword />} />
					<Route path="/change-password" exact element={<ChangePassword />} />
				</Routes>
			</div>
		</BrowserRouter>
	)
}

export default App
