import React, { useEffect } from "react"
import { connect } from "react-redux"
import { ToastContainer, toast } from "react-toastify"

const Toasts = props => {
	useEffect(() => {
		const type = props.message.type
		const message = props.message.message

		switch (type) {
			case "success":
				return toast.success(message)
			case "warning":
				return toast.warning(message)
			case "error":
				return toast.error(message)
			case "info":
				return toast.error(message)
			default:
				return
		}
	}, [props.message])

	return (
		<div>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				rtl={false}
				theme="colored"
				closeOnClick
				newestOnTop
				draggable
				pauseOnHover
				pauseOnfocusLoss
			/>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		message: state.Toast,
	}
}

export default connect(mapStateToProps)(Toasts)
