import "./Home.scss"
import React from "react"
import Countdown from "react-countdown"

import MainBanner from "./MainBanner/MainBanner"
// import CardMini from "../common/CardMini/CardMini"

const Home = () => {
	const timerRenderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return <span>soon.</span>
		}
		return (
			<span>
				in: {days} Days: {hours} Hours: {minutes} Minutes and: {seconds} Seconds
			</span>
		)
	}

	return (
		<div className="home-page">
			<div className="banner-wrapper">
				<MainBanner />
			</div>
			<br />
			<div className="text-section">
				<h4>
					www.ceylonsources.lk powerd by Digital Servicers Colombo &#40;Pvt&#41;
					Ltd. an B2B sourcing platform that has been driving global trade from
					Sri Lankan Products and Services.
				</h4>
				<br />
				<h4>
					The B2B website will be launched{" "}
					<Countdown date={"2022-07-31T00:00:00"} renderer={timerRenderer} />
				</h4>
			</div>
			{/* <h1>Knowledge Center</h1>
			<div className="knowledge-section">
				<CardMini />
				<CardMini />
				<CardMini />
				<CardMini />
			</div> */}
		</div>
	)
}

export default Home
