export const Required = value => (value ? undefined : "Required")

export const NumberToPhone = value => {
	if (!value) return ""
	const onlyNums = value && value.replace(/[^\d]/g, "")
	if (onlyNums.length <= 3) return onlyNums
	if (onlyNums.length <= 7)
		return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`
	return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
		6,
		9
	)}`
}

export const Money = value =>
	value && value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")

export const SerialNo = value => {
	if (!value) return ""
	const money = value && value.replace(/[\D]|(^0+)/g, "")
	if (money.length <= 3) return money
	if (money.length <= 6) return `${money.slice(0, 3)} ${money.slice(3, 6)}`
	if (money.length <= 9)
		return `${money.slice(0, 3)} ${money.slice(3, 6)} ${money.slice(6, 9)}`

	return `${money.slice(0, 3)} ${money.slice(3, 6)} ${money.slice(
		6,
		9
	)} ${money.slice(9, 12)}`
}

export const PasswordConfirm = values => {
	const errors = {}
	if (!values.password) {
		errors.password = "Required"
	}
	if (!values.confirm) {
		errors.confirm = "Required"
		errors.password = "Must match"
	} else if (values.confirm !== values.password) {
		errors.confirm = "Must match"
		errors.password = "Must match"
	}
	return errors
}

export const OnlyNumbersInt = value => value && value.replace(/[\D]|(^0+)/g, "")

export const OnlyNumbers = value => (value ? value.replace(/[^\d]/g, "") : "")

export const ToLowerCase = value => value && value.toLowerCase()

export const ToUpperCase = value => value && value.toUpperCase()

export const NoSpChars = value => (value ? value.replace(/[^\d\w.]/g, "") : "")

export const NoNumsAndSpChars = value =>
	value ? value.replace(/[^\s\w]|[\d_]/g, "") : ""
