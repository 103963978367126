import { configureStore, applyMiddleware, compose } from "@reduxjs/toolkit"
import thunk from "redux-thunk"
import Toast from "../reducers/toastsReducer"
import Auth from "../reducers/authReducer"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = configureStore(
	{
		reducer: {
			Toast,
			Auth,
		},
	},
	composeEnhancers(applyMiddleware(thunk))
)
