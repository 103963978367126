import { SIGNIN, REFRESH, SIGNOUT } from "../actions/Auth/types"

const INIT_AUTH_STATE = {
	access_token: "",
	refresh_token: "",
	type: "bearer",
	isLoggedIn: false,
}

const Auth = (state = INIT_AUTH_STATE, action) => {
	switch (action.type) {
		case SIGNIN:
			return { ...state, ...action.payload }
		case REFRESH:
			return { ...state, ...action.payload }
		case SIGNOUT:
			return { ...state, INIT_AUTH_STATE }
		default:
			return state
	}
}

export default Auth
