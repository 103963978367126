import "./Header.scss"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import Select from "react-select"
import { GoSearch } from "react-icons/go"

// import UserLoggedOut from "./UserLoggedOut/UserLoggedOut"
import UserLoggedIn from "./UserLoggedIn/UserLoggedIn"

const Header = () => {
	const SearchOptions = [
		{ value: "products", label: "Products" },
		{ value: "store", label: "Store" },
	]
	const [searchOption, setSearchOption] = useState(SearchOptions[0])

	return (
		<div className="header-main">
			<div className="container header-sub">
				<Link to="/">
					<img
						src="/images/Ceylon_Sources_Logo.jpg"
						alt="Ceylon Sources Logo"
						className="logo"
					/>
				</Link>
				<div className="search-bar">
					<Select
						defaultValue={searchOption}
						onChange={setSearchOption}
						options={SearchOptions}
						classNamePrefix="react-select"
						className="react-select-container"
					/>
					<input type="text" name="search" id="search" className="txt-search" />
					<div className="btn-wrapper-right">
						<button className="btn btn-normal btn-no-margin btn-no-radius">
							<span className="btn__text">Search</span>
							<span className="btn__icon">
								<GoSearch />
							</span>
						</button>
					</div>
				</div>
				<div className="user">
					<UserLoggedIn />
					{/* <UserLoggedOut /> */}
				</div>
			</div>
			<div className="container header-nav">
				<Select
					placeholder="Categories"
					classNamePrefix="react-select"
					className="react-select-container"
				/>
				<Link to="/" className="nav-links">
					<h3>Buy or Sell with Ceylon Sources</h3>
				</Link>
				<Link to="/" className="nav-links">
					<h3>Support Center</h3>
				</Link>
				<Link to="/" className="nav-links">
					<h3>Knowledge Base</h3>
				</Link>
			</div>
		</div>
	)
}

export default Header
