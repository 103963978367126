import "./UserLoggedIn.scss"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
	TbShoppingCart,
	TbUserCircle,
	TbMessage,
	TbHeart,
} from "react-icons/tb"

const UserLoggedIn = () => {
	const [userMenuOpen, setUserMenuOpen] = useState(false)

	const userMenu = () => {
		return (
			<div
				className={`menu user-menu ${!userMenuOpen ? "hidden" : ""}`}
				onClick={hideUserMenu}
			>
				<h3 className="menu-title">Wellcome User</h3>
				<Link to="/" className="menu-item">
					My Profile
				</Link>
				<Link to="/" className="menu-item">
					My Store
				</Link>
				<Link to="/" className="menu-item">
					Logout
				</Link>
			</div>
		)
	}

	const hideUserMenu = () => {
		setUserMenuOpen(!userMenuOpen)
	}

	return (
		<>
			<div className="logged_in-nav">
				<Link to="/" className="icon-link">
					<TbMessage />
				</Link>
				<Link to="/" className="icon-link">
					<TbShoppingCart />
				</Link>
				<Link to="/" className="icon-link">
					<TbHeart />
				</Link>
				<div
					className="icon-link"
					id="user-icon"
					style={{ fontSize: "3em" }}
					onClick={hideUserMenu}
				>
					<TbUserCircle />
				</div>
			</div>
			{userMenu()}
		</>
	)
}

export default UserLoggedIn
