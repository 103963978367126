import { TOAST_MESSAGE } from "../actions/types"

const Toast = (state = {}, action) => {
	switch (action.type) {
		case TOAST_MESSAGE:
			return { ...state, ...action.payload }
		default:
			return state
	}
}

export default Toast
